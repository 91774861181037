export const warningColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'fileName',
    key: 'fileName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'fileName' },
    width: 150
  },
  {
    title: '申请人',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    slots: { customRender: 'name' },
    ellipsis: true,
    width: 150
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',
    align: 'left',
    slots: { customRender: 'mobile' },
    ellipsis: true,
    width: 150
  },
  {
    title: '印章名称',
    dataIndex: 'sealName',
    key: 'sealName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'sealName' },
    width: 100
  },
  {
    title: '预计用印日期',
    dataIndex: 'sealTime',
    key: 'sealTime',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'sealTime' },
    width: 180
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 80
  }
]
